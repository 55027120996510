<template>
  <div class="clearBox2">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="allot-main">
      <div class="allot-main-left main-box-shadow">
        <div class="left-title">车队</div>
        <div class="allot-main-left-title p24px" >
          <el-input
            style="width: 180px" class="mr15px"
            placeholder="请输入车队名称"
            v-model="searchKey"
            clearable>
          </el-input>
          <el-button type="primary" @click="clickFilterText">搜索</el-button>
          <!-- accordion 手凤琴模式 -->
          <el-tree
            :data="motorcade"
            :props="defaultProps"
            default-expand-all
            highlight-current
            node-key="label"
            ref="tree" 
            @node-click="handleNodeClick"
            @node-expand="getUnallotCar"
            style="padding-top: 10px; overflow: auto;">
          </el-tree>
        </div>
      </div>
      <!-- 右侧列表 -->
      <div class="allot-main-right">
        <div style="height: calc(100% - 70px);overflow-x:hidden;backgorund-color:#fff">
          <el-scrollbar style="height: 100%;">
            <ayl-main title="已分配车辆" isCenter="center" padding="10px" :isCollapse="false">
              <div slot="main" style="padding: 24px 24px 20px 24px;height:100%">
                <ayl-table :table = "allotTable" @selection-change="RemoveAllotCar">
                  <div slot="ctrl-button">
                    <el-button type="danger" class="allot" @click="removeCar">移出车队</el-button>
                  </div>
                </ayl-table>
              </div>
            </ayl-main>

            <ayl-main title="未分配车辆" isCenter="center" padding="10px" :isCollapse="false">
              <div slot="main" style="padding: 24px 24px 20px 24px;height:100%">
                <ayl-table :table = "table" @selection-change="AddAllotCar">
                  <div slot="ctrl-button">
                    <el-button type="danger" class="allot" @click="allotCar">移入车队</el-button>
                  </div>
                </ayl-table>
              </div>
            </ayl-main>
          </el-scrollbar>
        </div>
          <div class="btn-wrap">
              <el-button type="primary" plain @click="$router.go(-1)">返回</el-button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        nav: [{name: "车辆管理", path: ""}, {name: "车队管理", path: "/vehicle-management/fleet-management"}, {name: "分配车队", path: ""}],
        list: [],
        options: [],
        value: [],
        AddCar: [],
        removeCarArray: [],
        loading: false,
        searchKey: null,
        allotTable: {
          api: vm.$api.allottedCarInfo,
          query: {
            groupId: '0',
            companyId: '0',
            queryContent: null,
          },
          hidePagination: true,
          // tableHeight: '177px',
          searchData: [{
            type: 'input',
            model: 'queryContent',
            placeholder: '请输入车牌号码'
          }],
          columns: [
          {
            type: 'selection',
            width: '50px'
          },{
            title: '车牌号',
            key: 'plateNumberString',
          },{
            title: '所属公司',
            key: 'carUnit',
          }]
        },
        table: {
          api: vm.$api.UnallottedCarInfo,
          query: {
            groupId: '0',
            companyId: '0',
            queryContent: null,
          },
          hidePagination: true,
          // tableHeight: '177px',
          searchData: [{
            type: 'input',
            model: 'queryContent',
            placeholder: '请输入车牌号码'
          }],
          columns: [
          {
            type: 'selection',
            width: '50px'
          },{
            title: '车牌号',
            key: 'plateNumberString',
          },{
            title: '所属公司',
            key: 'carUnit',
          }]
        },
        motorcade: [],
        defaultProps: {
          children: 'children',
          label: 'label'
        }
      }
    },
    methods:{
      /** 手动点击搜索 */
      async clickFilterText(){
        // this.$refs.tree.filter(this.filterText);
        this.motorcade = await this.$api.SearchMotorcade({queryContent: this.searchKey})
      },
      /** 查询车队下边的车辆list信息 */
      async handleNodeClick(data) {
        this.allotTable.query.groupId = data.groupId ? data.groupId : '0'
        this.allotTable.query.companyId = this.table.query.companyId = data.companyId ? data.companyId : '0'
        await this.$search(this.allotTable)
        await this.$search(this.table)
      },
      /** 查询公司未分配车辆list信息 展开公司查看车队的时候显示的数据列表 */
      async getUnallotCar(data){
        this.table.query.companyId = data.companyId
        await this.$search(this.table)
      },
      /** 获取移出车队的车牌号码数据 */
      RemoveAllotCar(val){
        this.removeCarArray =  val.map(e=>{
          return e.plateNumber
        })
      },
      /** 获取新增的车牌号码数据 */
      AddAllotCar(val){
        this.AddCar =  val.map(e=>{
          return e.plateNumber
        })
      },
      /** 移入车队 */
      async allotCar(){
        if(this.AddCar.length === 0){
          this.$message.warning('请先选中要移入的车辆！')
          return
        }
        if(this.allotTable.query.groupId !== '0'){
          await this.$api.UpdateAllottedCarTable({plateNumberList: this.AddCar,companyId: this.table.query.companyId,  groupId: this.allotTable.query.groupId})
          this.refresh()
        }else{
          this.$message.warning('请先选中要移入的车队！')
        }
      },
      /** 移出车队 */
      async removeCar(){
        if(this.removeCarArray.length === 0){
          this.$message.warning('请先选中要移出的车辆！')
          return
        }
        await this.$api.UpdateAllottedCarTable({plateNumberList: this.removeCarArray, companyId: this.allotTable.query.companyId, groupId: null})
        this.refresh()
      },
      /** 刷新三个列表的数据 */
      async refresh(){
        this.motorcade = await this.$api.SearchMotorcade({})
        await this.$search(this.allotTable)
        await this.$search(this.table)
      }
    },
    async mounted () {
      this.motorcade = await this.$api.SearchMotorcade({queryContent: null})
      // await this.$search(this.table)
    }
  }
</script>

<style lang='sass' scoped="scoped">
.clearBox2
  overflow-x: visible !important
  .allot-main
    width: 100%
    height: 100%
    &-left
      z-index: 333
      width: 320px
      height: 100%
      display: inline-block
      vertical-align: top
      background: rgba(255,255,255,1)
      overflow: auto
      &-title
        text-align: center
        padding: 24px
        @include clearfix
        .title
          font-size: $fontX
          color: $black
          border-bottom: 1px solid rgba(232,232,232,1)
    &-right
      margin-left: 24px
      display: inline-block
      width: calc(100% - 320px - 24px)
      height: calc(100% - 28px)
      background-color: #fff
.sub-nav-left
  background: rgba(255,255,255,1)
  box-shadow: 1px 0 6px 0 rgba(5,117,230,0.1)
  border-radius: 2px
.left-title
  padding: 10px
  text-align: center
  width: 100%
  font-size: $fontX
  color: $black
  border-bottom: 1px solid rgba(232,232,232,1)


.btn-wrap
  padding-top: 10px
  background-color:#fff
  border-top: 1px solid #eee
  text-align: center

/deep/ .ayl-main
  margin-bottom: 0
  width: 50%
  height: 100%
  overflow-y: scroll
/deep/ .el-scrollbar__view
  display: flex
  width: 100%
  height: 100%
/deep/ .el-table__body
  width: 100% !important
/deep/ .ayl-table
  height: 100%
/deep/ .ayl-main > div:last-child
  height: calc(100% - 40px)
/deep/ .el-table
  height: calc(100% - 48px)
  overflow-y: scroll
/deep/ .el-tree-node__content
  margin-bottom: 8px;
/deep/ .el-tree-node:focus > .el-tree-node__content
  background-color: #e4e7ed;
  color: #0575E6;
/deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content
  background-color: #e4e7ed;
  color: #0575E6;
  user-select: none;
/deep/ .el-table::before
  height: 0;
</style>
